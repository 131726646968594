import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ContactPerson from "../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../components/hero";
import { CaseList } from "../../components/caseList";

import doodle from "../../assets/images/services/backend.svg";

const BackendInfrastruktur = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-backend.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="de" langPath="service.backend.link">
      <Seo
        title="Backend und Infrastruktur – Monday Consulting"
        description="Unser Schwerpunkt liegt auf der Entwicklung moderner Infrastrukturen. Unsere Experten sind mit den führenden Technologien bestens vertraut, um Ihre Architektur modern, wartbar und performant zu gestalten."
        keywords="Backend, Architektur, Infrastruktur, Kubernetes, Java, Spring, Docker, Container, Microservices, DevOps, Entwicklung"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Hinter den Kulissen ganz vorne dabei"
        subline="Durch den Einsatz modernster Technologien bringen unsere Experten sie schneller ans Ziel."
        section="Backend & Infrastruktur"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Backend & Infrastruktur"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img
              className="w-75"
              alt="Zukunftsfähige Infrastruktur"
              src={doodle}
            />
          </div>
          <div className="col-md-6">
            <h2>Zukunftsfähige Infrastruktur</h2>
            <p>
              Innovationszyklen werden kürzer und die Ansprüche größer – eine
              moderne Infrastruktur wird demnach immer entscheidender für die
              digitale Wettbewerbsfähigkeit Ihres Unternehmens. Die Experten von
              Monday Consulting – ob Backend-Entwickler oder DevOps Engineers -
              sind mit den führenden Technologien bestens vertraut. Unser Ziel
              ist es, Ihre Architektur modern, wartbar und performant zu
              gestalten. Daher liegt unser Schwerpunkt auf der Entwicklung von
              Containerarchitekturen in Verbindung mit Microservices. Dabei
              legen wir höchsten Wert auf eine performante Entwicklung und einen
              sauberen sowie gut strukturierten Code, um stabile, schnelle und
              wartbare Applikationen zu gewährleisten.
            </p>
            <p>
              Der Einsatz von Docker und Kubernetes bietet ein Maximum an
              Flexibilität und Skalierbarkeit. Durch den umgebungsunabhängigen
              Betrieb sorgen Container nicht nur für die Ausfallsicherheit Ihrer
              Webseite sondern sparen auch Ressourcen. Eine Containerarchitektur
              lässt sich außerdem optimal für Microservices nutzen, die Ihnen
              eine Bandbreite an weiteren Vorteilen bieten. Jeder Service wird
              autonom entwickelt, hat eine klar definierte Schnittstelle und
              steht allein für sich. Damit verkürzen wir den Entwicklungszyklus
              und können auch Deployments und Updates noch schneller für Sie
              umsetzen. Außerdem wirkt sich die Funktionsfähigkeit eines
              Services nicht auf die angrenzenden Module aus. Anders als bei
              einem Monolith wird somit die Stabilität des Gesamtsystems
              gesichert.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">
            Backend & Infrastruktur im Detail
          </h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-java bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Java & Spring
            </h3>
            <p>
              Das breite Java-Ökosystem bildet das Fundament von
              Enterprise-Lösungen. Es ist die Technologie, die gleichzeitig
              modern und etabliert ist. Einerseits werden aktuelle Trends
              schnell aufgegriffen und ermöglichen eine zukunftssichere
              Weiterentwicklung. Anderseits sind Sprache und
              Entwicklungsprozesse ausgereift und unternehmenstauglich.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-kubernetes bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Kubernetes, Docker & DevOps
            </h3>
            <p>
              Die Erstellung von Software-Artefakten als Container-Images, zum
              Beispiel mit Docker, sichert die Lauffähigkeit in allen
              Software-Lebensphasen – von der Entwicklung bis zum
              Produktiveinsatz. Kubernetes hilft, diese Umgebungen
              bereitzustellen, die Artefakte auszuliefern und die benötigte
              Leistung bedarfsgerecht zu skalieren. Hiermit können die Vorteile
              moderner DevOps-Methoden ausgeschöpft werden.
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-microservices bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Microservices
            </h3>
            <p>
              Durch die Entwicklung der Features als einzelne Services sorgen
              wir für mehr Agilität und Flexibilität in der Entwicklung. Die
              Systeme werden wartbarer und können schneller weiterentwickelt
              werden. Einzelne Services sind unabhängig voneinander skalierbar.
              So gewährleisten wir die bestmögliche Performance und die
              Ausfallsicherheit des Gesamtsystems.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-api bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Drittsysteme & Schnittstellen
            </h3>
            <p>
              Wir entwickeln geeignete Schnittstellen (z.B. mit GraphQL), um so
              performant und sicher Daten aus beliebigen Drittsystemen Ihres
              Unternehmens abzurufen. Somit sind Sicherheitskonzepte (DMZ)
              gewährleistet und es gelangen nur die Daten an die Applikation,
              die explizit gefordert werden.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Ausgewählte Referenzen</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="hansemerkur,generali" />
      </div>

      <ContactPerson
        headline="Dürfen wir Ihnen behilflich sein?"
        text="Überzeugen Sie sich noch heute von unseren Leistungen und nehmen Sie direkt Kontakt mit uns auf. Wir stehen Ihnen mit Rat und Tat zur Seite."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Geschäftsführer"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default BackendInfrastruktur;
