import React from "react";
import { arrayOf, bool, node, oneOfType, string } from "prop-types";

const ContactPerson = ({
  contactEmail,
  contactPhone,
  headline,
  text,
  contactName,
  contactPosition,
  children,
  withGetInTouch,
}) => {
  return (
    <>
      <div
        className="container"
        id={withGetInTouch ? "get-in-touch" : "contact-person"}
      >
        <div className="row align-items-center">
          <div className="col-6 offset-3 col-sm-4 offset-sm-4 col-md-3 offset-md-1 mb-2">
            <div className="avatar">
              <div className="img">{children}</div>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="mb-3 text-center text-md-left">
              <h2 className="h4 mb-1">{headline}</h2>
              <p>{text}</p>
            </div>
            <div className="text-center text-md-left">
              <h3 className="h4 mb-1 text-size-md-5">
                {contactName}
                {contactPosition && `, ${contactPosition}`}
              </h3>
              {contactPhone && (
                <div>
                  <a
                    className="text-sans text-size-5 text-color-monday-green hover-underline"
                    href={`tel:${contactPhone.replaceAll(/\s/g, "")}`}
                  >
                    {contactPhone}
                  </a>
                </div>
              )}
              {contactEmail && (
                <div>
                  <a
                    className="text-sans text-size-5 text-color-monday-green hover-underline"
                    href={`mailto:${contactEmail}`}
                  >
                    {contactEmail}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {withGetInTouch && (
        <a
          href="#get-in-touch"
          className="get-in-touch bg-monday-green d-md-none"
          aria-label="Kontaktieren Sie uns"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z" />
          </svg>
        </a>
      )}
    </>
  );
};

ContactPerson.propTypes = {
  contactEmail: string,
  contactPhone: string,
  contactName: string,
  contactPosition: string,
  headline: string,
  text: string,
  children: oneOfType([node, arrayOf(node)]),
  withGetInTouch: bool,
};

ContactPerson.defaultProps = {
  withGetInTouch: true,
};

export default ContactPerson;
